import NextImage from 'next/image'
import { useRef } from 'react'
import styled from 'styled-components'

import { ButtonGroup, ButtonLink, Link, Text } from 'common/UI'
import { useScroll } from 'common/hooks/useScroll'
import { ClinicHeroStoryblok } from 'common/types'
import {
  getAnchorFromCmsLink,
  getImageAttributes,
  textByLine,
} from 'common/utils/content'
import { addAlphaToColor } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { Wrapper } from './CommonHero'

type ClinicHeroPorps = {
  block: ClinicHeroStoryblok
}

export const ClinicHero = ({ block, ...props }: ClinicHeroPorps) => {
  const {
    headline,
    address_title,
    address,
    see_map,
    open_hours_title,
    open_hours,
    links,
    images,
  } = block

  const hasPrimaryButton = links && links?.[0]?.label && links?.[0]?.link
  const hasSecondaryButton = links && links?.[1]?.label && links?.[1]?.link

  const { scrollPosition } = useScroll()
  const containerRef = useRef<HTMLDivElement>(null)

  const maxTranslate1 = 50
  const minTranslate1 = -25
  const maxTranslate2 = 20
  const minTranslate2 = -80
  const maxTranslate = 120

  const translations = (
    min: number,
    max: number,
    orientation: 'up' | 'down'
  ) => {
    return !containerRef.current
      ? 0
      : orientation === 'down'
      ? Math.max(
          min,
          Math.min(
            Math.floor(
              (scrollPosition / containerRef.current?.offsetHeight) *
                (max - min) +
                min
            ),
            max
          )
        )
      : Math.max(
          min,
          Math.min(
            Math.floor(
              (scrollPosition / containerRef.current?.offsetHeight) *
                (min - max) +
                max
            ),
            max
          )
        )
  }

  const translateXMobile = !containerRef.current
    ? 0
    : Math.min(
        Math.floor(
          (Math.max(0, scrollPosition) / containerRef.current?.offsetHeight) *
            maxTranslate
        ),
        maxTranslate
      )

  return (
    <>
      <Wrapper ref={containerRef} variant="side" {...props}>
        <InnerWrapper>
          <WrapperLeft>
            {headline && (
              <Text as="h1" variant="display" css={{ paddingBottom: '2.5rem' }}>
                {headline}
              </Text>
            )}
            <WrapperInnerLeft>
              <div css={{ maxWidth: '25.75rem' }}>
                {address_title && (
                  <StyledText as="p" variant="eighteen/regular">
                    {address_title}
                  </StyledText>
                )}

                {address && (
                  <Text as="p" variant="eighteen">
                    {address}
                  </Text>
                )}

                {see_map && see_map[0]?.link && (
                  <Link
                    isUnderlined
                    href={getAnchorFromCmsLink(see_map[0].link).href}
                    target={getAnchorFromCmsLink(see_map[0].link).target}
                    rel={getAnchorFromCmsLink(see_map[0].link).rel}
                    css={{ marginTop: '1.25rem' }}
                  >
                    {see_map[0].label}
                  </Link>
                )}

                {open_hours_title && (
                  <StyledText
                    as="p"
                    variant="eighteen/regular"
                    css={{ paddingTop: '1.25rem' }}
                  >
                    {open_hours_title}
                  </StyledText>
                )}

                {open_hours && (
                  <>
                    {textByLine(open_hours, (part) => (
                      <Text as="p" variant="eighteen">
                        {part}
                      </Text>
                    ))}
                  </>
                )}
              </div>

              {(!!hasPrimaryButton || !!hasSecondaryButton) && (
                <ButtonsHolder inlineFrom="sm">
                  {!!hasPrimaryButton && (
                    <ButtonLink
                      href={`tel:${links[0].link.url}`}
                      variant="accent"
                      leftIcon="phone"
                      css={{ margin: '0' }}
                    >
                      {links[0].label}
                    </ButtonLink>
                  )}

                  {!!hasSecondaryButton && (
                    <CMSLink
                      href={links[1].link}
                      variant="secondary"
                      css={{ margin: '0' }}
                    >
                      {links[1].label}
                    </CMSLink>
                  )}
                </ButtonsHolder>
              )}
            </WrapperInnerLeft>
          </WrapperLeft>
          <WrapperImages>
            <StyledImage
              src="/assets/lines.svg"
              alt="lines"
              css={{ position: 'absolute', left: -90, top: 0 }}
            />
            <StyledImage
              src="/assets/lines.svg"
              alt="lines"
              css={{ position: 'absolute', right: -90, bottom: 210 }}
            />
            <WrapperImagesMobile
              style={{
                transform: `translateX(-${translateXMobile}px)`,
              }}
            >
              {images && images[0] && (
                <WrapperRectangularImageMobile>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[0]).src}
                    alt={getImageAttributes(images[0]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImageMobile>
              )}
              <GradientDivMobile />
              {images && images[1] && (
                <WrapperRectangularImageMobile>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[1]).src}
                    alt={getImageAttributes(images[1]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImageMobile>
              )}
              {images && images[2] && (
                <WrapperRectangularImageMobile>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[2]).src}
                    alt={getImageAttributes(images[2]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImageMobile>
              )}
            </WrapperImagesMobile>
            <WrapperInnerImages
              style={{
                transform: `translateY(${translations(
                  minTranslate2,
                  maxTranslate2,
                  'up'
                )}px)`,
              }}
            >
              {images && images[0] && (
                <WrapperRectangularImage>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[0]).src}
                    alt={getImageAttributes(images[0]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImage>
              )}
              {images && images[1] && (
                <WrapperRectangularImage>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[1]).src}
                    alt={getImageAttributes(images[1]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImage>
              )}
            </WrapperInnerImages>
            <WrapperInnerImages
              style={{
                transform: `translateY(${translations(
                  minTranslate1,
                  maxTranslate1,
                  'down'
                )}px)`,
              }}
            >
              <GradientDiv />
              {images && images[2] && (
                <WrapperRectangularImage>
                  <NextImage
                    fill
                    priority
                    src={getImageAttributes(images[2]).src}
                    alt={getImageAttributes(images[2]).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperRectangularImage>
              )}
            </WrapperInnerImages>
          </WrapperImages>
        </InnerWrapper>
      </Wrapper>
    </>
  )
}

const InnerWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;

  width: 100%;
  max-width: 90rem;

  padding: 2rem 1rem;

  z-index: ${({ theme }) => theme.zIndex.low + 1};

  ${({ theme }) => theme.media.md} {
    padding: 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 5rem;
    max-height: 39.75rem;
  }
`

const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    padding-top: 5rem;
    max-width: 31.25rem;
    flex-shrink: 0;
  }
`

const WrapperInnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const StyledText = styled(Text)`
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 60)};
`

const ButtonsHolder = styled(ButtonGroup)`
  display: none;
  padding-top: 2.5rem;
  width: 100%;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.md} {
    display: flex;
    flex-direction: row;
    padding-left: 0.75rem;
    gap: 0.75rem;
  }
`

const WrapperRectangularImage = styled.div`
  display: none;
  position: relative;
  width: 19.0625rem;
  height: 26.25rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;

  ${({ theme }) => theme.media.lg} {
    display: block;
  }
`

const WrapperImages = styled.div`
  display: flex;
  gap: 1.25rem;
  position: relative;
`

const WrapperInnerImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const StyledImage = styled.img`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;
  }
`

const WrapperImagesMobile = styled.div`
  display: flex;
  gap: 1.25rem;
  padding-top: 2.5rem;
`

const WrapperRectangularImageMobile = styled.div`
  display: block;
  position: relative;
  width: 18.125rem;
  height: 12.8125rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`

const GradientDiv = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;
    width: 19.0625rem;
    height: 13.75rem;
    background: ${({ theme }) =>
      `linear-gradient(135deg, ${theme.colors.palette.gradient.yellow}, ${theme.colors.palette.gradient.coral})`};
    border-radius: 1.25rem;
  }
`

const GradientDivMobile = styled.div`
  width: 12.625rem;
  height: 12.8125rem;
  background: ${({ theme }) =>
    `linear-gradient(135deg, ${theme.colors.palette.gradient.yellow}, ${theme.colors.palette.gradient.coral})`};
  border-radius: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`
